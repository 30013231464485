import * as React from "react";

import {
  SectionContainer,
  SectionWrapper
} from "../components/Section";

import Layout from "../components/Layout";
import Head from "../components/Head";

import { PageHero } from "../components/Hero";


const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <Head
        title="404: Not Found"
      />

      <SectionWrapper
        background="#F1F5FB"
        padding="153px 0 138px"
      >
        <SectionContainer>
          <PageHero
            heading="404: Not Found"
            description="We're sorry, but this page doesn't exist."
            alignment="left"
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export default NotFoundPage;
